<template>
  <div class="noData textCenter">
       <el-image
      :src="url"
      fit="fit"></el-image>
      <p class="graySty">{{NoDataText}}</p>
  </div>
</template>

<script>
export default {
    props:{
        NoDataText:{
            type:String,
            default:'暂无数据'
        }
    },
    data(){
        return{
            url:require('@/assets/image/noData.png')
        }
    }

}
</script>

<style>

</style>