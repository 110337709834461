<template>
  <div class="detailsData">
    <el-row type="flex" justify="space-between">
      <el-col :span="10">
        <div class="zoomImg">
          <img :src="imageUrl" alt="" />
          <!-- <pic-zoom :url="imageUrl"></pic-zoom> -->
        </div>
        <ul class="displayRow flexWrap">
          <li
            v-for="(item, index) in goodsImageUrl"
            :key="index"
            class="flexItem pointer"
            :class="actived === index ? 'actived' : ''"
            @click="changeImg(index, item)"
          >
            <el-image :src="item" fit="fill" class="ImgItem"></el-image>
          </li>
        </ul>
      </el-col>
      <el-col :span="14">
        <h2><span class="redSty" v-if="isPutAway === 1" >【已下架】</span>{{ title }}</h2>
        <div class="details">
          <div class="displayRow con">
            <label class="labelRow">价格：</label
            ><span class="priceSty fontBold price">￥{{ SalePrice ? SalePrice :'--' }}</span>
          </div>

          <div>
            <div
              class="attrCon con"
              v-for="(item, index) in AttrInfoList"
              :key="index"
            >
              <Attr
                :dataList="item"
                :index="index"
                @e-changeAttr="changeAttr"
              />
            </div>
          </div>
          <div class="displayRow con">
            <label class="labelRow fontSm">数量：</label>
            <el-input-number
              v-model="num"
              :min="1"
              size="mini"
            ></el-input-number>
            <span style="margin-left: 5px">件</span>
          </div>
          <!-- <div class="displayRow con" v-if="outerSkuCode">
            <label class="labelRow fontSm">商品条码：</label>
            <span>{{outerSkuCode}}</span>
          </div> -->
        </div>
        <div class="displayRow">
          <div class="left">
            <div class="tipText">
              <span class="labelText">温馨提示:</span
              ><span>商品问题扫码联系客服咨询</span>
            </div>
            <div class="tipText">
              <span class="labelText">订购:</span><span>扫码直接订购</span>
            </div>
            <div class="tipText displayRow">
              <span class="labelText">服务:</span>
              <div class="serveText">
                <i class="el-icon-success"></i><span>正品保障</span>
              </div>
              <div class="serveText">
                <i class="el-icon-success"></i><span>安全支付</span>
              </div>
              <div class="serveText">
                <i class="el-icon-success"></i><span>闪电发货</span>
              </div>
              <div class="serveText">
                <i class="el-icon-success"></i><span>售后无忧</span>
              </div>
            </div>
            <div class="displayRow" style="padding-top: 25px">
              <el-button type="primary" style="margin-right: 20px"   plain
                >立即购买</el-button
              >
              <el-popover
                placement="top-start"
                width="300"
                trigger="click"
                v-model="popoverFlag"
              >
                <div class="successCon displayRow">
                  <i class="el-icon-circle-check"></i>
                  <div @click="toShopCar" style="cursor: pointer;">
                    <p class="successText">成功加入购物车！</p>
                    <p class="fontSm">
                      您可以去购物车查看商品信息，下载购物清单
                    </p>
                    <i class="el-icon-close" @click="popoverFlag = false"></i>
                  </div>
                </div>
                <el-button
                  type="primary"
                  slot="reference"
                  @click="addShopCar"
                  >加入购物车</el-button
                >
              </el-popover>
            </div>
            <!-- <div style="padding-top: 25px" v-else>
              <el-button type="info" plain :disabled="true" >商品已下架</el-button>
            </div> -->
          </div>
          <div class="right">
            <p class="graySty fontSm textCenter" style="margin-bottom: 5px">
              手机端浏览
            </p>
            <qr-code :appSrc="h5Url" :logoSrc="logoImg" :size="100"></qr-code>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 商品详情 -->
    <div class="goodDetails" v-loading="Descloading">
      <div class="detailsTitle">
        <span class="titleText">商品详情</span>
      </div>
      <div
        class="goodDesc textCenter"
        v-if="goodsDesc && !Descloading"
        v-html="goodsDesc"
      ></div>
      <NoData v-if="!goodsDesc && !Descloading" />
    </div>
  </div>
</template>
<script>
var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?5d6b1b39fc8f63765c4a2e30df4bd41d";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
</script>
<script>
import { getDetails,getH5Url,addCar,getCar } from "@/assets/js/api.js";
import QrCode from "@/components/QrCode";
import Attr from "@/components/Attr";
import { compare } from "@/assets/js/common";
import PicZoom from "vue-piczoom";
import NoData from "@/components/NoData";
import Bus from "@/assets/js/bus.js";
import {nanoid} from 'nanoid';
export default {
  name: "DetailsData",
  components: {
    QrCode,
    Attr,
    PicZoom,
    NoData,
  },
  data() {
    return {
      imageUrl: "",
      logoImg: require("@/assets/image/logo.jpg"),
      goodsImageUrl: [],
      actived: 0,
      num: "",
      goodsDesc: "",
      h5Url: "",
      title: "",
      isPutAway:'',
      AttrInfoList: [],
      attrArr: [],
      skuMap: {},
      SalePrice: "",
      Descloading: true,
      shopCar: [],
      popoverFlag: false,
      skuCode:'',
      skuId:'',
      goodsId:'',
      outerSkuCode:'',
    };
  },
  methods: {
    //去购物车
    toShopCar(){
       this.$router.push('/ShopCar');
    },
    changeAttr(data) {
      this.num = 1;
      let index = this.attrArr.findIndex((v) => v.index === data.index);
      if (index > -1) {
        this.attrArr.splice(index, 1, data);
      } else {
        this.attrArr.push(data);
      }
      this.attrArr.sort(compare("index"));
      let str = this.getAttrPrice();
      if (this.skuMap[str]) {
        this.SalePrice = this.skuMap[str].salePrice;
        if (this.skuMap[str].imageUrl) {
          this.imageUrl = this.skuMap[str].imageUrl;
        }
         if (this.skuMap[str].skuId) {
          this.skuId = this.skuMap[str].skuId;
        }
        if(this.skuMap[str].outerSkuCode){
          this.skuCode = this.skuMap[str].outerSkuCode;
        }
      }
    },

    getAttrPrice() {
      let str = "";
      str = this.attrArr.reduce((key, item) => {
        return key + ":" + item.key;
      }, "");
      str = str.substring(1, str.length);
      return str;
    },

    changeImg(index, img) {
      this.actived = index;
      this.imageUrl = img;
    },

    // 获取详情
    getDetail() {
      this.Descloading = true;
      let params = { goodsId: this.classifyId };
      getDetails(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.skuCode = res.data.result.outerGoodsCode;
            this.goodsId = res.data.result.goodsId;
            let goodsDesc = res.data.result.goodsDesc;
            this.goodsDesc = goodsDesc.replace(/\\/g, "");
            this.title = res.data.result.title;
            this.outerGoodsCode = res.data.result.outerGoodsCode;
            this.goodsImageUrl = res.data.result.goodsImageUrl;
            this.imageUrl = this.goodsImageUrl[0];
            this.AttrInfoList = res.data.result.selectedSaleAttrInfoList;
            this.skuMap = res.data.result.skuMap;
            this.isPutAway = res.data.result.isPutAway;
            this.outerSkuCode = res.data.result.skuList[0].outerSkuCode;
          }
        })
        .finally(() => {
          this.Descloading = false;
        });
    },

    // 获取二维码接口
    getQrCode() {
      let params = { goodsId: this.classifyId };
      getH5Url(params).then((res) => {
        if (res.data.code === 200) {
          this.h5Url = res.data.result.h5Url;
        }
      });
    },

      // 去登录
    toLogin(){
      let path = this.$route.fullPath;
      let nanoidStr = nanoid();
      localStorage.setItem(nanoidStr,path);
      let url = `https://qiandian8888.com/soutu-server/sys/thirdLogin/render/wechat_open?state=${nanoidStr}`;
      window.location.href = url;
    },

    // 加入购物车
    addShopCar() {
      if(localStorage.getItem('UserInfo')){ //存在token直接加入
      let userInfo  = localStorage.getItem('UserInfo') ? JSON.parse(localStorage.getItem('UserInfo')):{};
      getCar(userInfo.token).then(res =>{
        if(res.data.code === 200){
        this.shopCar = res.data.result === null ? [] : res.data.result.cartList;
        this.carInfo(userInfo.token);
        }
      })
         
      }else{ //登录
        this.toLogin();
      }
    },

    //购物车
    carInfo(token){  
      let attrStr = "";
      if(this.attrArr.length > 0){
         this.attrArr.forEach((item) => {
        attrStr += item.value + ",";
      }); 
      attrStr = attrStr.substring(0, attrStr.length - 1);
      }else{
        attrStr = this.title;
      }
      let con = {
        skuCode:this.skuCode,
        skuName: attrStr,
        skuPrice: this.SalePrice,
        skuImageUrl: this.imageUrl,
        skuId:this.skuId,
        skuNum: this.num,
        skuUnitName:'个',
        totalPrice:this.SalePrice * this.num,
        goodsId:this.goodsId,
        goodsTitle:this.title,
        outerGoodsCode:this.outerGoodsCode,
        classifyId:this.classifyId,
      };
      this.shopCar.unshift(con);
      let params = {cartList:this.shopCar}
      addCar(params,token).then(res =>{
        if (res.data.code === 200) {
           setTimeout(() => {    
               
        this.popoverFlag = false;
      }, 5000);
        }
      })
    }
  },
  watch: {
    "shopCar.length": {
      immediate: true,
      handler(val) {
        Bus.$emit("e-shopCarLen", val);
      },
    },
  },
  mounted() {},
  created() {
    this.classifyId = this.$route.query.classifyId ? this.$route.query.classifyId : this.$route.query.goodsId;
    this.getDetail();
    this.getQrCode();
  },
};
</script>

<style scoped>
.detailsData {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.zoomImg {
  width: 415px;
  height: 415px;
}
.zoomImg img {
  width: 100%;
  height: 100%;
}

.flexItem {
  margin-right: 20px;
  margin-top: 20px;
  width: 66px;
  height: 66px;
  padding: 5px;
  box-sizing: border-box;
}
.ImgItem {
  width: auto;
  height: 100%;
}
.actived {
  border: 1px solid #0388dd;
}
.details {
  margin-top: 20px;
  background-color: #f5f5f5;
  padding: 20px;
}
.price {
  font-size: 24px;
}

.con {
  margin-bottom: 25px;
}
/* .selected{
    position: absolute;
    bottom:-1px;
    right:-1px;
    width: 0;
    height: 0;
    border:10px solid #0388dd;
    border-left: 10px solid transparent!important;
    border-top: 10px solid transparent!important;
} */
/* .checkIcon{
    position: absolute;
    bottom:-10px;
    right:-10px;
    font-size: 12px;
    color:#fff;
} */

.tipText {
  font-size: 13px;
  padding-top: 12px;
}
.labelText {
  width: 70px;
  display: inline-block;
}
.serveText {
  margin-right: 15px;
}
.el-icon-success {
  color: #0388dd;
  margin-right: 5px;
  font-size: 16px;
}
.goodDetails {
  padding: 10px;
  border: 1px solid #eee;
  margin-top: 30px;
}
.detailsTitle {
  padding-top: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  text-align: center;
}
.titleText {
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 10px;
  border-bottom: 2px solid #0388dd;
  display: inline-block;
}
.right {
  margin-left: 80px;
}
.goodDesc {
  font-size: 0;
}
.goodDesc img{
  width:1200px;
}
.successCon {
  position: relative;
}
.el-icon-circle-check {
  color: #0388dd;
  font-size: 30px;
  margin-right: 20px;
}
.successText {
  font-size: 18px;
  padding: 10px 0;
}
.el-icon-close {
  position: absolute;
  top: -5px;
  right: -3px;
  font-size: 20px;
  color: #ccc;
}
</style>