<template>
  <div class="box displayRow">
       <label class="labelRow fontSm">{{dataList.name}}</label>
               <ul class="displayRow flexWrap">
                  <li v-for="(j, k) in dataList.attrValueList" :key="k" class="cateItem">
                      <div class="attrVal" :class="selected === k ? 'selectedCate' :''" @click="changeAttr(k,j)">{{j.value}}</div>
                  </li>
               </ul>
  </div>
</template>

<script>
export default {
    props:{
        dataList:{
            type:Object,
            default:() =>{}
        },
        index:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
             selected:-1,

        }
    },
    methods:{
      changeAttr(k,item){
          this.selected = k;
          let con = {
              index:this.index,
              key:item.key,
              value:item.value
          }
          this.$emit('e-changeAttr',con);
      }  
    },
    created(){
        let defaultKey = this.dataList.attrValueList[0];
        this.changeAttr(0,defaultKey);
    }

}
</script>

<style scoped>
.cateItem{
    margin-right: 15px;
    margin-bottom: 10px;
    position: relative;
}
.attrVal{
  padding:8px;
  background-color: #ddd;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
}
.selectedCate{
   background-color: #0388dd;
   color:#fff;
}
</style>